// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import routes from './router';

const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  routes: [
    // 路由使用 Layout.vue 作为布局
    {
      path: '/blog',
      component: () => import('@/components/BlogCP/LayoutComponent.vue'),
      children: [
        ...routes,
        {
          path: 'post/:id',
          name: 'PostDetail',
          component: () => import('@/components/BlogCP/PostDetail.vue'), // 假设 PostDetail.vue 位于 components 目录
          props: true,
          meta: {
            title: '文章详情',
          },
        },
      ],
    },
    // 独立的顶级路由
    {
      path: '/navigation',
      name: 'NavigationPage',
      component: () => import('@/views/Navigation.vue'),
    },
    {
      path: '/explore',
      name: 'ExplorePage',
      component: () => import('@/views/Explore.vue'),
    },
    {
      path: '/projects',
      name: 'ProjectsPage',
      component: () => import('@/views/Projects.vue'),
    },
    // 测试路由
    {
      path: '/test',
      name: 'Test',
      component: () => import('@/components/BlogCP/SettingCard/SettingList.vue'),
    },
    // 全局的路由
    {
      path: '/login',
      name: 'LoginPage',
      component: () => import('@/components/GlobalCP/LoginPage.vue'),
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: () => import('@/components/GlobalCP/SignupPage.vue'),
    },
    // 根路径重定向到 /blog
    {
      path: '/',
      redirect: '/projects',
    },
    // 404 页面
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@/views/NotFound.vue'),
    },

  ],
});

export default router;
