<template>
  <div id="app">
    <!-- 这里可以放置公共的导航栏或其他内容 -->
    <ThemeToggle />
    <router-view></router-view>
    
  </div>
</template>

<script>
import ThemeToggle from './components/ThemeToggle.vue';

export default {
  name: 'App',
  components: {
    ThemeToggle
  }
}
</script>

<style>

#app {
 
}
</style>
