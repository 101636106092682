// src/plugins/axios.js
import axios from 'axios';

const apiClient = axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL,//项目
  baseURL: process.env.VUE_APP_API_BASE_URL_SERVER,//服务器
  // baseURL: process.env.VUE_APP_API_BASE_URL_LOCAL,//本地
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // 允许携带凭证（如Cookie）
});

// 如果后续需要处理响应拦截器，可以在此添加
// 例如处理全局错误等

export default apiClient;
