import {createApp} from 'vue';
import router from './router';
import App from './App.vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIcons from '@element-plus/icons'
import './assets/global.css';
import DatePicker  from 'ant-design-vue';
import { createPinia } from 'pinia';
import axios from './plugins/axios';
import { useUserStore } from '@/stores/user';
import 'csshake/dist/csshake.min.css'; // 引入 CSSShake
const pinia = createPinia();
const app = createApp(App);
app.use(router);
/* eslint-disable no-undef */
for (const name in ElIcons){
	app.component(name, ElIcons[name])
}
app.use(ElementPlus);
app.use(pinia);
app.config.globalProperties.$axios = axios; //将 axios 添加到全局属性中
app.use(DatePicker);
const userStore = useUserStore();
userStore.initialize();

app.mount('#app');