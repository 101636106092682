// src/stores/user.js
import { defineStore } from 'pinia';
import { ref } from 'vue';
import apiClient from '@/plugins/axios';
import { useRouter } from 'vue-router';

export const useUserStore = defineStore('user', () => {
  const user = ref(null);
  const token = ref(null);
  const isLoading = ref(false);
  const error = ref(null);

  const router = useRouter();

  // 设置 Token
  const setUser = (responseData) => {
    token.value = responseData.token; // 假设 responseData.token 是 token
    user.value = null; // 重置用户信息
    localStorage.setItem('token', token.value);
    console.log('Token 已保存:', token.value);
    // 不再需要设置 axios 的默认头部，因为拦截器已经处理
  };

  // 登录方法
  const login = async (credentials) => {
    isLoading.value = true;
    error.value = null;
    try {
      const response = await apiClient.post('/login', credentials);
      console.log('登录响应:', response.data); // 调试响应数据
      if (response.data.status === 'success') {
        setUser(response.data); // 传递整个响应数据
        await fetchUser(); // 登录后获取用户信息
        router.push('/'); // 登录成功后跳转到主页
      } else {
        error.value = response.data.message || '登录失败，请重试。';
      }
    } catch (err) {
      console.error('登录失败:', err);
      error.value = err.response?.data?.message || '登录失败，请重试。';
      throw err;
    } finally {
      isLoading.value = false;
    }
  };

  // 获取用户信息
  const fetchUser = async () => {
    isLoading.value = true;
    error.value = null;
    try {
      const response = await apiClient.get('/user');
      if (response.data.status === 'success') {
        user.value = response.data.data; // 正确设置用户信息
        printUserInfo(user.value);
      } else {
        throw new Error(response.data.message || '获取用户信息失败。');
      }
    } catch (err) {
      printUserInfo(user.value);
      console.error('获取用户信息失败:', err);
      error.value = err.response?.data?.message || '获取用户信息失败。';
      logout(); // 如果获取用户信息失败，执行登出
    } finally {
      isLoading.value = false;
    }
  };

  // 打印用户信息到控制台的方法
  const printUserInfo = (userInfo) => {
    console.log('用户信息:', userInfo);
  };

  // 登出方法
  const logout = () => {
    user.value = null;
    token.value = null;
    localStorage.removeItem('token');
    // 不再需要删除 axios 的默认头部，因为拦截器会自动处理
    router.push('/login'); // 登出后跳转到登录页
  };

  // 初始化用户状态
  const initialize = () => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      token.value = storedToken;
      // 不再需要设置 axios 的默认头部，因为拦截器已经处理
      fetchUser();
    }
  };

  return {
    user,
    token,
    isLoading,
    error,
    setUser,
    login,
    fetchUser,
    logout,
    initialize,
  };
});
